<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"> Delegaciones </v-card-title>
      <v-data-table
        :headers="headers"
        :items="delegacionesItems"
        class="elevation-1"
        :search="search"
        :loading="isLoading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2" align="end" v-if="canCreate">
                <v-btn color="primary" @click="openModal()"> Nuevo </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="16"
                v-on="on"
                @click="openModal(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar delegación</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="16"
                v-on="on"
                @click="deleteDel(item.delId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar delegación</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <div v-if="openModalEdit">
        <v-dialog
          v-model="openModalEdit"
          max-width="50%"
          @keydown.esc="closeAndReload"
          persistent
        >
          <EditDelegacionesRnos
            :editDelegacion="editDelegacion"
            :rnosId="rnosId"
            @closeAndReload="closeAndReload"
          ></EditDelegacionesRnos>
        </v-dialog>
      </div>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'25%'"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="mt-2" color="primary" @click="closeModalDeleg">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import EditDelegacionesRnos from "@/components/modules/afiliaciones/afiliados/configuracion/EditDelegacionesRnos.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditDelegacionesRnos
  },
  name: "DelegacionesRnos",
  props: ["rnosId"],
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    title: enums.titles.RNOS,
    titleDelete: "¿Eliminar Delegación?",
    delegacionesItems: [],
    editDelegacion: {},
    headers: [
      {
        text: "Nombre",
        value: "delNombre",
        sortable: true
      },
      {
        text: "Número",
        value: "delNro",
        sortable: false
      },
      {
        text: "Código",
        value: "delCodigo",
        sortable: false
      },
      {
        text: "Libro",
        value: "delLibro",
        sortable: false
      },
      {
        text: "Tomo",
        value: "delTomo",
        sortable: false
      },
      {
        text: "Fecha",
        value: "delFecha",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadDelegaciones();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getDelegacionesByRnos: "configAfiliaciones/getDelegacionesByRnos",
      deleteDelegacion: "configAfiliaciones/deleteDelegacion",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_DELEGACION:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_DELEGACION:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_DELEGACION:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadDelegaciones() {
      this.isLoading = true;
      const data = await this.getDelegacionesByRnos(this.rnosId);
      this.delegacionesItems = data;
      this.isLoading = false;
    },

    deleteDel(id) {
      this.showDeleteModal = true;
      this.itemToDelete = id;
      
    },
    async confirmDelete() {
      const response = await this.deleteDelegacion(this.itemToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadDelegaciones();
      }
    },
    openModal(id) {
      this.openModalEdit = true;
      this.editDelegacion = id;
    },

    closeModalDeleg() {
      this.$emit("closeModalDelegacion");
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadDelegaciones();
    }
  }
};
</script>

<style lang="scss" scoped></style>
